'use strict';

angular
  .module('workflow')

  .factory('TradosRangesAssets', function() {
    const ranges = [
      // DO NOT CHANGE ORDER!!!
      // Le fasce Trados sono elencate dalla meno cara alla più cara,
      // ma il precedente funzionamento prevedeva che le fasce di prezzo Landoor
      // fossero elencate dalla più cara alla meno cara.
      // Da quando è introdotta la corrispondenza 1:1 tra fasce Trados
      // e fasce Landoor, occorre fare attenzione all'ordine e usare .map(...).reverse()
      // quando necessario per non rompere i prezzi già inseriti,
      // perché i prezzi sono salvati come array di numeri,
      // dove la fascia corrisponde alla posizione.
      {
        name: 'PerfectMatch',
        price_range: null,
        quantity: null
      },
      {
        name: 'Context Match',
        price_range: null,
        quantity: null
      },
      {
        name: 'Repetitions',
        trados_names: ['Repetitions', 'Ripetizioni'],
        price_range: null,
        quantity: null
      },
      {
        name: 'Cross-file Repetitions',
        trados_names: ['Cross-file Repetitions', 'Ripetizioni tra file'],
        price_range: null,
        quantity: null
      },
      {
        name: '100%',
        price_range: null,
        quantity: null
      },
      {
        name: '95% - 99%',
        price_range: null,
        quantity: null
      },
      {
        name: '85% - 94%',
        price_range: null,
        quantity: null
      },
      {
        name: '75% - 84%',
        price_range: null,
        quantity: null
      },
      {
        name: '50% - 74%',
        price_range: null,
        quantity: null
      },
      {
        name: 'New',
        trados_names: ['New', 'New/AT', 'Nuovi/AdaptiveMT'],
        price_range: null,
        quantity: null
      }
      // DO NOT CHANGE ORDER!!!
    ];

    const price_ranges = ranges.map((_, i) => i + 1);

    return {
      ranges,
      price_ranges
    };
  });
