angular
  .module('library')

  .directive('listUnits', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        units: '=ngModel',
        taskId: '<',
        taskName: '<',
        isTranslation: '<',
        readonly: '<',
        langRequired: '<'
      },
      templateUrl: 'custom/library/views/partials/listUnits.html',

      controller: [
        '$scope',
        '$controller',
        '$timeout',
        '$filter',
        '$state',
        '$translate',
        'ValueLang',
        'TradosRangesAssets',
        function(
          $scope,
          $controller,
          $timeout,
          $filter,
          $state,
          $translate,
          ValueLang,
          TradosRangesAssets
        ) {
          var currentLang = $translate.proposedLanguage() || $translate.use();

          $scope.tradosRangesNames = TradosRangesAssets.ranges
            .map(({ name }) => name)
            .reverse();

          $scope.getName = function(unit) {
            return ValueLang(unit, 'name', currentLang).value;
          };

          $scope.setPrice = function(newPrice, unit, range) {
            //console.log('setPrice', newPrice, range);
            if (unit.list && unit.list.prices) {
              unit.list.prices[range ? range : 0] = newPrice;
            } else {
              var rangeArray = [];
              rangeArray[range ? range : 0] = newPrice;
              unit.list = {
                dateStart: new Date(),
                price: rangeArray
              };
            }
          };

          $scope.getPrice = (combo, hasRanges) => {
            let { list } = combo;
            list = list || {};
            combo.list = list;
            let { notes, prices } = list;
            notes = notes || [];
            list.notes = notes;
            prices = prices || [];
            list.prices = prices;

            if (hasRanges) {
              if (prices.length < TradosRangesAssets.ranges.length) {
                // Se le fasce di prezzo pre-esistenti sono meno delle fasce Trados, aggiungere le mancanti.
                prices.push(
                  ...new Array(
                    TradosRangesAssets.ranges.length - prices.length
                  ).fill(undefined)
                );
              }
            } else {
              !prices.length && prices.push(undefined);
            }

            return prices;
          };

          $scope.getPriceString = function(combo) {
            const price = $scope.getPrice(combo);
            if (price) {
              return price.join(' - ');
            }
          };

          $scope.addRange = function(combo, hasRanges) {
            const price = $scope.getPrice(combo);
            if (price) {
              price.push(undefined);
            } else {
              combo.list = {
                dateStart: new Date(),
                prices: hasRanges
                  ? new Array(TradosRangesAssets.ranges.length).fill(undefined)
                  : [undefined]
              };
            }
          };

          // $scope.removeRange = function(combo) {
          //   if ($scope.getPrice(combo)) {
          //     $scope.getPrice(combo).pop();
          //   }
          // };

          $scope.addFirstRange = function(combo, hasRanges) {
            const price = $scope.getPrice(combo);
            if (!price || !price.length) {
              $scope.addRange(combo, hasRanges);
            }
          };

          $scope.searchCompany = function() {
            $state.go('root.app.simple.companies', {
              associating: true,
              units: angular.copy($scope.units),
              task: {
                task_id: $scope.taskId,
                task_name: angular.copy($scope.taskName),
                translate: $scope.isTranslation,
                lang_required: $scope.langRequired
              }
            });
          };

          $scope.addCombo = unit => {
            const { lang_required } = $scope;
            const { combos = [] } = unit;

            unit.combos = combos;

            const combo = {};

            combo.list = {
              dateStart: new Date(),
              prices: []
            };

            if (lang_required) {
              combo.source = {};
            }

            if (lang_required === 2) {
              combo.target = {};
            }

            combos.push(combo);
          };

          $scope.deleteCombo = (unit, comboIndex) => {
            const { combos = [] } = unit;

            combos.splice(comboIndex, 1);
          };
        }
      ]
    };
  });
