angular
  .module('company')

  .directive('priceRanges', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        list: '<'
      },
      templateUrl: 'custom/company/views/partials/priceRanges.html',
      controller: [
        '$scope',
        'TradosRangesAssets',
        function($scope, TradosRangesAssets) {
          $scope.tradosRangesNames = TradosRangesAssets.ranges
            .map(({ name }) => name)
            .reverse();

          $scope.$watch('list', list => {
            if (!list) return;
            const { notes } = list;
            list.notes = notes || [];
          });

          $scope.$watchCollection('list.prices', (prices, old) => {
            if (!prices || !old) return;

            if (
              prices.some &&
              prices.some((price, index) => price !== old[index])
            ) {
              $scope.list.dateStart = new Date();
              $scope.list.customTask = true;
            }
          });
        }
      ]
    };
  });
